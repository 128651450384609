import { isBlogArticle } from 'lib/article/isBlogArticle';
import { isShellArticle } from 'lib/article/isShellArticle';
import {
  RECOMMENDATIONS_ENABLED,
} from 'lib/brandFeatures';
import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';
import { verticalSlugMap } from 'lib/vertical';

/**
 * Boolean function to determine if recommendations should be disabled for given article and vertical
 *
 * @param {Article} article
 * @param {VerticalType} vertical
 * @returns {Boolean}
 */
export const disableRecommendations = (article, vertical) => {
  if (!article || !vertical) return false;

  const disabledTaxonomies = {
    food: true,
  };

  const nativeAd = article?.nativeAd ?? null;
  const ecommerceEnabled = article?.ecommerceEnabled ?? null;
  const hideRecommendations = article?.presentation?.hideRecommendations ?? null;


  const primarySectionName = article?.taxonomy?.primarySection?.name ?? '';

  const {
    today: TODAY,
  } = verticalSlugMap;

  if (vertical === TODAY && primarySectionName !== 'Shop') {
    return false;
  }

  return !getFeatureConfigForBrand(RECOMMENDATIONS_ENABLED, vertical)
      || hideRecommendations
      || nativeAd
      || ecommerceEnabled
      || disabledTaxonomies[primarySectionName.toLowerCase()]
      || isShellArticle(article)
      || isBlogArticle(article);
};
