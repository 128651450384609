import React from 'react';
import PropTypes from 'prop-types';

import { block as parentBlock } from './helper';

const block = `${parentBlock}__search-form`;

class ProviderSelectResults extends React.Component {
  static propTypes = {
    filter: PropTypes.bool,
    inputRef: PropTypes.shape({
      current: PropTypes.shape({
        focus: PropTypes.func,
      }),
    }).isRequired,
    onClickClear: PropTypes.func,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    filter: false,
    onClickClear: Function.prototype,
    onChange: Function.prototype,
  };

  constructor(props) {
    super(props);
    this.state = {
      showClear: false,
    };
  }

  handleChange = (e) => {
    const { target: { value } } = e;
    const { filter } = this.props;
    // Show search clear button
    this.setState({
      showClear: value.length > 0,
    });
    // Pass click event up to callback prop
    const { onChange } = this.props;
    onChange(e, filter);
  }

  handleClear = (e) => {
    // Hide clear button
    this.setState({
      showClear: false,
    });
    // Pass click event up to callback prop
    const { onClickClear } = this.props;
    onClickClear(e);
  }

  render() {
    const { showClear } = this.state;
    const {
      filter,
      inputRef,
    } = this.props;

    // Filtering has different behavior for the results list
    let placeholder = 'Type provider name to search';
    if (filter) {
      placeholder = 'Type provider name to filter';
    }

    return (
      <div className={block}>
        <form onSubmit={(e) => { e.preventDefault(); }}>
          <input
            type="text"
            ref={inputRef}
            placeholder={placeholder}
            onChange={this.handleChange}
            className={`${block}__input-text ${block}__input-text__search`}
          />
        </form>
        <div className={`${block}__search-icon`}>
          {!showClear && (
            <button
              type="button"
              className={`${parentBlock}__button ${block}__button`}
              onClick={() => { inputRef.current.focus(); }}
            >
              <span className="icon icon-search" />
            </button>
          )}
          {showClear && (
            <button
              type="button"
              className={`${parentBlock}__button ${block}__button`}
              onClick={this.handleClear}
            >
              <span className="icon icon-close" />
            </button>
          )}
        </div>
      </div>
    );
  }
}

export default ProviderSelectResults;
