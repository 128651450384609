import React, { useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Ad from 'components/Ad';
import './styles.themed.scss';

export const AdSponsor = () => {
  const { t } = useTranslation();

  const [rendered, setRendered] = useState(false);

  const handler = (mpsAd) => {
    mpsAd.onRender((event) => {
      if (!event || event.empty) {
        return;
      }
      setRendered(true);
    });
  };

  // Links to navbar pre-HFS
  // https://github.com/nbcnews/ramen/blob/0acf32d86f30bcb6567728dab0e1e2debdd1483c/src/components/Navbar/index.jsx#L399
  // https://github.com/nbcnews/ramen/blob/0acf32d86f30bcb6567728dab0e1e2debdd1483c/src/components/Navbar/styles.scss#L451

  return (
    <div
      className={classNames(
        'ad-sponsor',
        { 'ad-sponsor--displayed': rendered },
      )}
    >
      <span className="ad-sponsor__label">
        {t('Sponsored By')}
      </span>
      <Ad
        adClass="ad-sponsor__utility"
        handler={handler}
        refreshInterval={0}
        slot="sponsorlogo"
      />
    </div>
  );
};
