import { isBrowser } from 'lib/BrowserDetection';
import React, { useRef } from 'react';

/**
 * Get the best life cycle hook for updating the callback ref
 * @param {boolean} isSSR
 * @returns {React.EffectCallback}
 */
function getInsertionEffect(isSSR) {
  return isSSR ? React.useEffect : React.useInsertionEffect || React.useLayoutEffect;
}

/**
 * Given an event handler, returns a stable reference. This function serves the same purpose as
 * useEffectEvent will in later versions of react
 * @template {Function} T - The type of the event callback function
 * @param {T} fn - The event callback function
 * @returns {T} - Event callback function wrapper
 */
export function useEvent(fn) {
  const eventRef = useRef(fn);

  getInsertionEffect(!isBrowser())(() => {
    eventRef.current = fn;
  }, [fn]);

  /** @type {T} */
  const callback = /** @type {any} */ ((/** @type {[]} */ ...args) => eventRef.current?.(...args));

  return useRef(callback).current;
}
