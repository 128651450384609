import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Picture } from 'components/Picture';
import TypeIcon from 'components/TypeIcon';

import AIMS_FLAVORS from 'lib/aimsFlavors';

import LogoNewsNow from '../Logo/NewsNow';
import LogoNewsSpecialReport from '../Logo/NewsSpecialReport';

const block = 'mini-player';

const LOGOS = {
  newsnow: LogoNewsNow({ block }),
  newsspecialreport: LogoNewsSpecialReport({ block }),
};

class MiniPlayerTease extends Component {
  static propTypes = {
    guid: PropTypes.string,
    brand: PropTypes.string,
    isFlagged: PropTypes.bool,
    useAnimatedTease: PropTypes.bool,
    headline: PropTypes.string,
    fallbackHeadline: PropTypes.string,
    staticTeaseImage: PropTypes.objectOf(PropTypes.string),
    fallbackTeaseImage: PropTypes.objectOf(PropTypes.string),
    url: PropTypes.string,
  };

  static defaultProps = {
    guid: null,
    brand: 'newsnow',
    isFlagged: true,
    useAnimatedTease: true,
    headline: null,
    fallbackHeadline: null,
    staticTeaseImage: {},
    fallbackTeaseImage: {},
    url: 'https://www.nbcnews.com/now',
  };

  getTeaseImage = () => {
    const { staticTeaseImage, fallbackTeaseImage, useAnimatedTease } = this.props;
    if (!useAnimatedTease && staticTeaseImage.url) {
      // if animated tease isn't being used and static tease image url exists
      return staticTeaseImage;
    }
    return fallbackTeaseImage;
  }

  render() {
    const {
      brand,
      fallbackHeadline,
      headline,
      isFlagged,
      url,
    } = this.props;
    const headlineText = headline || fallbackHeadline;
    const teaseImage = this.getTeaseImage();

    const pictureData = {
      className: 'db',
      responsiveFlavors: {
        s: AIMS_FLAVORS.MINI_PLAYER_TEASE_140x80,
      },
      computedValues: {
        teaseImage: {
          url: {
            primary: teaseImage.url || undefined,
          },
          altText: teaseImage.alt || '',
        },
      },
      layout: 'responsive',
      flavor: AIMS_FLAVORS.MINI_PLAYER_TEASE_140x80,
    };

    const icid = 'now_hp_header';

    return (
      <div className="mini-player">
        <button
          className="mini-player__button"
          onClick={() => {
            window.location = url;
          }}
          data-icid={icid}
          type="button"
        >
          {pictureData.computedValues.teaseImage.url.primary && (
            <div className="mini-player__tease">
              <Picture testId="mini-player__tease-picture" {...pictureData} />
              <TypeIcon className="mini-player__typeicon" type="video" />
            </div>
          )}
          <div className="mini-player__content">
            {brand && LOGOS[brand]}
            <h1 className="mini-player__headline">
              {isFlagged && (
                <span className="mini-player__live-flag">
                  LIVE
                  :&nbsp;
                </span>
              )}
              {headlineText}
            </h1>
          </div>
        </button>
      </div>
    );
  }
}

export default MiniPlayerTease;
