import PropTypes from 'prop-types';

export default PropTypes.shape({
  header: PropTypes.shape({
    css: PropTypes.shape({
      link: PropTypes.arrayOf(
        PropTypes.string,
      ),
    }),
    js: PropTypes.shape({
      html: PropTypes.arrayOf(
        PropTypes.string,
      ),
      src: PropTypes.arrayOf(
        PropTypes.string,
      ),
    }),
    html: PropTypes.arrayOf(
      PropTypes.string,
    ),
  }),
  footer: PropTypes.shape({
    css: PropTypes.shape({
      link: PropTypes.arrayOf(
        PropTypes.string,
      ),
    }),
    js: PropTypes.shape({
      html: PropTypes.arrayOf(
        PropTypes.string,
      ),
      src: PropTypes.arrayOf(
        PropTypes.string,
      ),
    }),
    html: PropTypes.arrayOf(
      PropTypes.string,
    ),
  }),
});
