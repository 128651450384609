import React from 'react';
import { useTranslation } from 'react-i18next';
import { SkipLinkOverlay } from 'components/SkipLinkOverlay';

/**
 * Overlay for the "Skip to Content" button that appears when a user
 * tabs through the page
 * @returns {React.JSX} React Component
 */
function SkipToContentOverlay() {
  const { t } = useTranslation();

  /**
   * @returns {void}
   */
  const navigateToMainContent = () => {
    const mainContent = document.querySelector('#header-end');
    if (mainContent) {
      mainContent.scrollIntoView();
      mainContent.focus();
    }
  };

  return (
    <SkipLinkOverlay
      buttonText={t('Skip to Content')}
      onClick={navigateToMainContent}
      buttonSize="fixed"
    />
  );
}

export { SkipToContentOverlay };
