import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash.get';

import { getMiniPlayerData as getMiniPlayerDataAction } from 'redux/modules/navbar';

import Breakpoints from 'lib/Breakpoints';
import { ENABLE_MINI_TEASE } from 'lib/brandFeatures';
import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';
import { miniPlayerTease as miniPlayerTeasePropType } from 'lib/CustomPropTypes';
import { stripQueryParams } from 'lib/urlUtils';

import tease from './Tease';

import './styles.themed.scss';

const mapStateToProps = ({
  navbar,
  router,
  shared,
}) => ({
  miniPlayerData: navbar.miniPlayerData,
  pageView: shared.pageView,
  path: router.path,
  vertical: shared.vertical,
});

const mapActionToProps = (dispatch) => ({
  getMiniPlayerData: (vertical) => dispatch(getMiniPlayerDataAction(vertical)),
});

class MiniPlayerTease extends React.Component {
  static propTypes = {
    getMiniPlayerData: PropTypes.func,
    miniPlayerLoaded: PropTypes.bool,
    miniPlayerData: PropTypes.oneOfType([
      PropTypes.string,
      miniPlayerTeasePropType,
    ]),
    useLocalTease: PropTypes.bool,
    onShowTease: PropTypes.func,
    pageView: PropTypes.string,
    path: PropTypes.string,
    vertical: PropTypes.string.isRequired,
  };

  static defaultProps = {
    getMiniPlayerData: Function.protoype,
    miniPlayerLoaded: false,
    miniPlayerData: {
      showTease: false,
    },
    useLocalTease: false,
    onShowTease: Function.prototype,
    pageView: null,
    path: null,
  };

  componentDidMount() {
    const { miniPlayerLoaded } = this.props;
    if (!miniPlayerLoaded) {
      const { getMiniPlayerData, vertical } = this.props;
      // Feature enabled
      const enabled = this.isEnabled();
      // Fetch mini player configuration data
      if (enabled) {
        getMiniPlayerData(vertical);
      }
    }
  }

  componentDidUpdate(prevProps) {
    const {
      miniPlayerData: {
        showTease: wasShown,
      },
    } = prevProps;

    const {
      miniPlayerData: {
        showTease: isShown,
      },
    } = this.props;

    if (wasShown === false && isShown === true && this.shouldRender()) {
      const { onShowTease } = this.props;
      onShowTease();
    }
  }

  /**
   * Get enabled configuration value
   */
  isEnabled = () => {
    const { vertical } = this.props;
    return getFeatureConfigForBrand(ENABLE_MINI_TEASE, vertical);
  }

  isCover = () => {
    const { pageView, path } = this.props;
    return pageView === 'front' && stripQueryParams(path) === '/';
  }

  /**
   * Function that uses miniPlayerData fetched from static-api to determine if page should render.
   * If there is no miniPlayerData for the vertical, miniplayer will not render.
   * Returns true if page is in includedPageTypes and not in excludedPages.
   * @returns {Boolean}
   */
  shouldRender = () => {
    if (!this.isEnabled()) {
      return false;
    }

    const { miniPlayerData } = this.props;
    if (miniPlayerData) {
      const {
        pageView,
        path,
      } = this.props;

      const {
        includedPageTypes,
        excludedPages,
      } = miniPlayerData;

      const isCover = this.isCover();

      if (includedPageTypes || excludedPages) {
        // Cover Mobile
        if (isCover && typeof includedPageTypes.coverMobile !== 'undefined' && Breakpoints.isS()) {
          return includedPageTypes.coverMobile;
        }
        // Cover
        if (isCover && typeof includedPageTypes.cover !== 'undefined') {
          return includedPageTypes.cover;
        }
        // Front / Article
        if (includedPageTypes[pageView]) {
          // Excluded pages
          const excludedArray = (typeof excludedPages === 'string'
              && excludedPages.split(/\n|\n\r/))
            || [];
          return !excludedArray.includes(stripQueryParams(path));
        }
      }
    }
    return false;
  }

  render() {
    if (!this.shouldRender()) {
      return null;
    }

    const {
      miniPlayerData,
      miniPlayerData: {
        brand,
      },
      useLocalTease,
      pageView,
      path,
      vertical,
    } = this.props;

    const miniTeaseConfig = getFeatureConfigForBrand(ENABLE_MINI_TEASE, vertical);
    const teaseName = useLocalTease
      ? get(miniTeaseConfig, [brand, 'local'])
      : get(miniTeaseConfig, [brand, 'default']);

    const TeaseComponent = tease[teaseName];

    if (!TeaseComponent) {
      return null;
    }

    return (
      <TeaseComponent
        {...miniPlayerData}
        pageView={pageView}
        path={path}
      />
    );
  }
}

export default connect(mapStateToProps, mapActionToProps)(
  MiniPlayerTease,
);
