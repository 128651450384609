import MSNBCAdsAreDown from './msnbc/error/ads-are-down.svg';
import MSNBCGeneric from './msnbc/error/generic.svg';
import MSNBCStreamIsDown from './msnbc/error/stream-is-down.svg';
import MSNBCUnsupportedBrowser from './msnbc/error/browser-is-not-supported.svg';

export default {
  msnbc: {
    adsAreDown: MSNBCAdsAreDown,
    generic: MSNBCGeneric,
    streamIsDown: MSNBCStreamIsDown,
    unsupportedBrowser: MSNBCUnsupportedBrowser,
  },
};
