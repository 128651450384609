import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { providerGenericPropType } from 'lib/CustomPropTypes/TVE';

import { block as parentBlock } from './helper';

const block = `${parentBlock}__search-results`;

function ProviderSelectResults({ fill, results, onClick }) {
  return (
    <div
      className={classNames(
        block,
        { [`${block}--fill`]: fill },
      )}
    >
      {results.map((res) => (
        <button
          key={res.id}
          type="button"
          onClick={() => { onClick(res.id); }}
          className={`${parentBlock}__button ${block}__button`}
          data-testid={`${block}__button`}
        >
          {res.displayName}
        </button>
      ))}
    </div>
  );
}

ProviderSelectResults.propTypes = {
  fill: PropTypes.bool,
  onClick: PropTypes.func,
  results: PropTypes.arrayOf(
    PropTypes.shape(providerGenericPropType),
  ),
};

ProviderSelectResults.defaultProps = {
  fill: false,
  onClick: Function.prototype,
  results: [],
};

export default ProviderSelectResults;
