/* eslint-disable max-len */
import PropTypes from 'prop-types';
import React from 'react';

export const MsnbcLiveLogo = ({ block }) => (
  <span data-testid="mini-player__logo--msnbc">
    <svg className={`${block}__logo ${block}__logo-text`} viewBox="0 0 50 11" xmlns="http://www.w3.org/2000/svg" fill="currentColor" aria-labelledby="live_tv_logo">
      <title id="live_tv_logo">Live TV</title>
      <path d="M0.672119 0.919922V10.9999H6.67212V9.22392H2.83212V0.919922H0.672119Z" />
      <path d="M7.62524 0.919922V10.9999H9.78524V0.919922H7.62524Z" />
      <path d="M15.5732 10.9999L18.4852 0.919922H16.4532L14.5972 7.71992H14.5332L12.6932 0.919922H10.4852L13.3972 10.9999H15.5732Z" />
      <path d="M25.2357 9.22392H21.2997V6.74392H24.2757V4.99992H21.2997V2.69592H25.2037V0.919922H19.1877V10.9999H25.2357V9.22392Z" />
      <path d="M34.4722 10.9999V2.13592H38.6162V0.919922H28.9682V2.13592H33.0642V10.9999H34.4722Z" />
      <path d="M45.2501 10.9999L49.2021 0.919922H47.7461L44.5621 9.41592H44.4981L41.2661 0.919922H39.7461L43.6981 10.9999H45.2501Z" />
    </svg>
  </span>
);

MsnbcLiveLogo.propTypes = {
  block: PropTypes.string,
};

MsnbcLiveLogo.defaultProps = {
  block: 'mini-player',
};
