import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { schedule as schedulePropType } from 'lib/CustomPropTypes/TVE';
import { loadScheduleAction } from 'redux/modules/tve';

export default function ScheduleProviderExport(WrappedComponent) {
  class ScheduleProvider extends React.Component {
    scheduleTimeout = null;

    static propTypes = {
      epgCurrent: PropTypes.shape(schedulePropType),
      epgLoading: PropTypes.bool,
      epgUpcoming: PropTypes.arrayOf(
        PropTypes.shape(schedulePropType),
      ),
      loadSchedule: PropTypes.func,
      vertical: PropTypes.string.isRequired,
    };

    static defaultProps = {
      epgCurrent: {},
      epgLoading: false,
      epgUpcoming: [],
      loadSchedule: Function.prototype,
    };

    componentDidMount() {
      // Fetch schedule
      const { loadSchedule, vertical } = this.props;
      loadSchedule(vertical);
      // Timeout to check schedule for update
      this.addScheduleUpdateEvent();
    }

    componentDidUpdate({ epgCurrent: { guid: prevGuid } }) {
      const {
        epgCurrent: {
          guid: currentGuid,
        },
      } = this.props;

      // Set new schedule update if show changed
      if (currentGuid && prevGuid !== currentGuid) {
        this.addScheduleUpdateEvent();
      }
    }

    componentWillUnmount() {
      clearTimeout(this.scheduleTimeout);
    }

    addScheduleUpdateEvent = () => {
      const { epgCurrent } = this.props;
      const { startTime } = epgCurrent;
      const runtime = get(epgCurrent, 'program.runtime', 3600);

      // Start time + run time - now = seconds until end of show
      const diff = (startTime + (runtime * 1000)) - (new Date()).getTime();

      // Clear existing countdowns
      clearTimeout(this.scheduleTimeout);

      // Start a new countdown to the end of the current show
      if (!Number.isNaN(diff)) {
        this.scheduleTimeout = setTimeout(this.updateSchedule, diff);
      }
    };

    updateSchedule = () => {
      const { loadSchedule, vertical } = this.props;
      loadSchedule(vertical);
    };

    render() {
      const {
        epgCurrent,
        epgLoading,
        epgUpcoming,
        ...props
      } = this.props;

      return (
        <WrappedComponent
          epgCurrent={epgCurrent}
          epgLoading={epgLoading}
          epgUpcoming={epgUpcoming}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      );
    }
  }

  // Connect higher order component
  const mapStateToProps = ({ shared, tve }) => ({
    epgCurrent: tve.epgCurrent,
    epgLoading: tve.epgLoading,
    epgUpcoming: tve.epgUpcoming,
    vertical: shared.vertical,
  });

  const mapActionsToProps = (dispatch) => ({
    loadSchedule: (vertical) => dispatch(loadScheduleAction(vertical)),
  });

  return connect(mapStateToProps, mapActionsToProps)(
    ScheduleProvider,
  );
}
