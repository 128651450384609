import React from 'react';

import { block } from './helper';

function ProviderSelectLoader() {
  return (
    <div className={`${block}__loader-icon`}>
      <div>
        <span className="icon icon-dot" />
        <span className="icon icon-dot" />
        <span className="icon icon-dot" />
      </div>
      <p>
        Connecting
      </p>
    </div>
  );
}

export default ProviderSelectLoader;
