import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './styles.themed.scss';

function AlertBanner({ alertText }) {
  const [closed, handleClose] = useState(false);
  if (closed === true) return null;
  return (
    <>
      <style>
        {`
        .alert-banner {
          display: none;
        }
        `}
      </style>
      <div className="alert-banner">
        {alertText}
        <button
          type="button"
          aria-label="close"
          className="alert-banner__close-button icon icon-close"
          onClick={() => handleClose(!closed)}
        />
      </div>
    </>
  );
}
AlertBanner.propTypes = {
  alertText: PropTypes.string.isRequired,
};

export { AlertBanner };
