export default function VerticalLink(vertical) {
  switch (vertical) {
    case 'news':
    case 'today':
    case 'msnbc':
    case 'noticias':
      return '';
    default:
      return vertical;
  }
}
