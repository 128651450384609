import { isBlogArticle } from 'lib/article/isBlogArticle';
import { verticalSlugMap } from 'lib/vertical';

/**
 * Determines which commerce related recommendation component to display if any
 * @param {Article} article
 * @param {VerticalType} vertical
 * @returns {Boolean | VerticalType} Boolean or vertical
 */
export const shouldRenderEcommerceRecommendations = (article, vertical) => {
  if (!article || !vertical) return false;

  const {
    today: TODAY,
    news: NEWS,
  } = verticalSlugMap;

  const ecommerceEnabled = article?.ecommerceEnabled ?? null;
  const primarySectionName = article?.taxonomy?.primarySection?.name ?? '';

  const subTypeIsNotBlog = !isBlogArticle(article);
  const isValidECommerceConfig = ecommerceEnabled && subTypeIsNotBlog;
  const isValidECommerceVertical = vertical === TODAY || vertical === NEWS
    ? primarySectionName === 'Shop' || primarySectionName === 'Shopping'
    : false;

  if (isValidECommerceConfig && isValidECommerceVertical) {
    return vertical;
  }

  return false;
};
