import React from 'react';
import get from 'lodash.get';
import classNames from 'classnames';
import { marquee as MarqueePropType } from 'lib/CustomPropTypes';
import { useVertical } from 'lib/Hooks';

import { IconClose } from 'components/Icon/Close';
import Link from 'components/Link';

import styles from './styles.module.scss';

const stripTags = (content) => content.replace(/<[^>]+>/g, '');

function Breaking({ marquee }) {
  const { vertical } = useVertical();
  const [isHidden, setIsHidden] = React.useState(false);

  const content = get(marquee, 'body[0].html', '');
  const primaryUrl = get(marquee, 'item.url.primary', false);
  const marqueeType = get(marquee, 'marqueeType', '');

  if (vertical === 'select') {
    return null;
  }

  return (
    <div
      className={classNames(
        styles.marqueeBreaking,
        {
          [styles.hidden]: isHidden,
          [styles.breakingNews]: marqueeType === 'breakingNews',
        },
      )}
      data-testid="marqueeBreaking"
    >
      <style>
        {`
        :root {
          --breaking-marquee-init-height: 48px;
        }
      `}
      </style>
      <div className={styles.inner}>
        {(() => {
          // Linked to content.
          if (primaryUrl) {
            return (
              <Link to={primaryUrl} className={styles.content} data-testid="breaking-content">
                {stripTags(content || 'Breaking news')}
                <span className={classNames(styles.icon, 'icon icon-arrow-link')} data-testid="breaking-icon" />
              </Link>
            );
          }
          // Links within text.
          if (content.search(/<a href/) !== -1) {
            /* eslint-disable react/no-danger */
            const substr = content.split('href="');
            const link = substr[1].split('"')[0];
            return (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
              <p className={styles.content} data-testid="breaking-content">
                <span dangerouslySetInnerHTML={{ __html: content }} data-icid="body-top-marquee" />
                <a href={link} target="_blank" rel="noreferrer" data-icid="body-top-marquee">
                  <span className={classNames(styles.icon, 'icon icon-arrow-link')} data-testid="breaking-icon" />
                </a>
              </p>
            );
            /* eslint-enable react/no-danger */
          }
          // Just text.
          return (
            <p className={styles.content} data-testid="breaking-content">
              {content}
            </p>
          );
        })()}
        <button
          aria-label="Close"
          className={styles.closeButton}
          onClick={() => { setIsHidden(true); }}
          type="button"
        >
          <IconClose />
        </button>
      </div>
    </div>
  );
}

Breaking.propTypes = {
  marquee: MarqueePropType.isRequired,
};

export { Breaking };
