import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';

/**
 * Hook to add an init callback for OneTrust to open the OneTrust modal based on the query param
 * `?onetrust=open`
 * @param {boolean} shouldOpenProp Whether or not to open the modal based on the query param. Acts
 * as a global switch beyond just the presence of the query param (e.g. if there's no footer, etc)
 */
export function useOpenOneTrustIfQueryParam(shouldOpenProp) {
  const [hasAddedCallback, setHasAddedCallback] = useState(false);
  const [hasEmptyOneTrust, setHasEmptyOneTrust] = useState(null);
  const router = useRouter();

  const shouldOpenQueryParam = router?.query?.onetrust === 'open';

  const shouldAddCallback = !hasAddedCallback && shouldOpenProp && shouldOpenQueryParam;

  useEffect(() => {
    if (shouldAddCallback) {
      const cachedOptanonWrapper = window.OptanonWrapper;
      setHasEmptyOneTrust(cachedOptanonWrapper?.toString() === 'function () { }');

      if (hasEmptyOneTrust || hasEmptyOneTrust === null) {
        window.OptanonWrapper = (...args) => {
          window.OneTrust?.ToggleInfoDisplay?.();
          if (typeof cachedOptanonWrapper === 'function') {
            cachedOptanonWrapper(...args);
          }
        };
        setHasEmptyOneTrust(false);
        setHasAddedCallback(true);
      }
    }
  }, [shouldAddCallback, hasEmptyOneTrust]);
}
