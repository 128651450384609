import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';

import Breakpoints from 'lib/Breakpoints';
import { schedule as schedulePropType } from 'lib/CustomPropTypes/TVE';
import { stripQueryParams } from 'lib/urlUtils';

import ScheduleProvider from 'components/TVE/ScheduleProvider';
import StandaloneSignIn from 'components/TVE/StandaloneSignIn';
import TypeIcon from 'components/TypeIcon';
import LoadingText from 'components/packages/Waffle/LoadingPlaceholders/LoadingText';

const block = 'mini-player';

class MsnbcTVE extends React.Component {
  static propTypes = {
    epgCurrent: PropTypes.shape(schedulePropType),
    epgLoading: PropTypes.bool,
    headline: PropTypes.string,
    pageView: PropTypes.string,
    path: PropTypes.string,
    url: PropTypes.string.isRequired,
  };

  static defaultProps = {
    epgCurrent: {},
    epgLoading: false,
    headline: null,
    pageView: null,
    path: null,
  };

  getTease = () => {
    const {
      epgCurrent,
    } = this.props;

    // Default to current schedule
    if (epgCurrent?.thumbnail) {
      return (
        <video width="142px" height="80px" autoPlay loop muted playsInline>
          <source src={epgCurrent.thumbnail} type="video/mp4" />
        </video>
      );
    }

    return null;
  }

  getHeadline = () => {
    const {
      epgCurrent,
      epgLoading,
      headline,
    } = this.props;

    // Manual override
    if (headline) {
      return headline;
    }

    if (epgLoading) {
      return <LoadingText />;
    }

    // Default to current schedule
    const current = get(epgCurrent, 'program.title', null);
    return (
      <>
        Watch Live:
        &nbsp;
        {current}
      </>
    );
  }

  render() {
    const {
      pageView,
      path,
      url,
    } = this.props;

    // Special treatment mobile/cover
    const mobileCover = Breakpoints.isS() && pageView === 'front' && stripQueryParams(path) === '/';

    return (
      <div className={`${block} ${mobileCover ? `${block}--light` : ''}`}>
        <button
          className={`${block}__button`}
          onClick={() => {
            window.location = url;
          }}
          type="button"
        >
          <div className={`${block}__tease`}>
            {this.getTease()}
            <TypeIcon className={`${block}__typeicon`} type="video" />
          </div>
          <div className={`${block}__content`}>
            <span className={`${block}__logo icon icon-msnbc-tve`} />
            <h1 className={`${block}__headline`}>
              {this.getHeadline()}
            </h1>
          </div>
        </button>

        {!mobileCover && (
          <div className={`${block}__sign-in`}>
            <StandaloneSignIn
              className={`${block}__sign-in-button`}
              redirectUrl={url}
            >
              <span className="icon icon-lock" />
              <span className={`${block}__sign-in-button-text`}>
                Sign In
              </span>
            </StandaloneSignIn>
          </div>
        )}
      </div>
    );
  }
}

export default ScheduleProvider(MsnbcTVE);
