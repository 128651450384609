/**
 * Boolean function to check if ad should show
 * @param {object} article
 * @returns {Boolean}
 */
export const shouldShowAd = (article) => {
  if (!article) return false;

  const nativeAd = article?.nativeAd ?? null;
  const adsEnabled = article?.adsEnabled ?? null;
  return !nativeAd && !!adsEnabled;
};
