import React from 'react';
import PropTypes from 'prop-types';

import VerticalLogo, { THEME } from '../Vertical';

const Logo = ({ color, active }) => <VerticalLogo vertical="msnbc" color={color} active={active} />;

Logo.propTypes = {
  color: PropTypes.oneOf(Object.values(THEME)),
  active: PropTypes.bool,
};

Logo.defaultProps = {
  color: THEME.DARK,
  active: false,
};

export default Logo;
