/**
 * Boolean function to determine if a given article is a shell presentation
 *
 * @param {object} article
 * @returns {Boolean}
 */
export const isShellArticle = (article) => {
  if (!article) return false;
  const style = article?.presentation?.style ?? null;
  return style === 'shell';
};
