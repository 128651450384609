import React from 'react';
import { marquee as MarqueePropType } from 'lib/CustomPropTypes';

import { PymFrame } from 'components/Pym/Frame';
import styles from './styles.module.scss';

/**
 * @param {{ marquee: { embed?: object } }} arg0
 * @returns {React.ReactElement}
 */
function Embed({ marquee }) {
  const embed = marquee?.embed;
  const embedUrl = embed?.url?.trim();
  const embedHtml = embed?.preloadedHtml;

  const cleanedEmbedUrl = embedUrl?.replace(/&(childId|parentUrl)=[^&]+/g, '');

  return (
    <div className={styles.marqueeEmbed} data-testid="marqueeEmbed">
      <PymFrame srcDoc={embedHtml} url={cleanedEmbedUrl} title="marquee" />
    </div>
  );
}

Embed.propTypes = {
  marquee: MarqueePropType.isRequired,
};

export { Embed };
