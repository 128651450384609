import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import getConfig from 'next/config';

import { RouteContext } from 'lib/ContextTypes';
import { stripTrailingSlash } from 'lib/urlUtils';

import styles from './styles.module.scss';

function Navbar(props) {
  let {
    html,
  } = props;

  const {
    active,
    hasGlobalInsert,
    hasLocalInsert,
    hasMobileNavInsert,
    shortcuts,
    showGlobal,
    showIdentity,
    showPolitics,
    theme,
    useSmallNav,
    localAnimationDuration,
  } = props;

  const {
    publicRuntimeConfig: {
      CNBC_STAGE_DOMAIN,
      ENABLE_RELATIVE_LINKS,
    },
  } = getConfig();

  // Remove production hostname from links in navbar html
  if (ENABLE_RELATIVE_LINKS) {
    const route = React.useContext(RouteContext);
    // Production hostname
    const prodHostname = `https://www.${route.domain}`;

    // Find links to root hostname - non capture hostname without trailing slash
    const regexRoot = new RegExp(`(?:${prodHostname})(?!/)`, 'g');
    // Replace links to root first
    html = html.replace(regexRoot, '/');

    // Find all other hostname non-search links
    const regexSub = new RegExp(`(?:${prodHostname})(/(?!search|archive[/'"])[^'"]*)`, 'g');
    // Then replace hostname on sub links
    html = html.replace(regexSub, '$1');
  }

  // Replace CNBC URLs with configured stage domain
  if (CNBC_STAGE_DOMAIN) {
    const regexCnbc = new RegExp('https://www.cnbc.com', 'g');
    html = html.replace(regexCnbc, stripTrailingSlash(CNBC_STAGE_DOMAIN));
  }

  const [isScrolled, setIsScrolled] = React.useState(false);

  const customLocalDuration = typeof localAnimationDuration === 'number'
    && !Number.isNaN(localAnimationDuration)
    ? { '--hfs-local-duration': `${localAnimationDuration}s` }
    : null;


  React.useEffect(() => {
    if (window.scrollY) {
      setIsScrolled(true);
    }
  }, []);

  return (
    <nav
      style={customLocalDuration}
      suppressHydrationWarning // content from HFS is mixed SSR and hydration, won't match ramen SSR
      className={classNames(
        'hfsh init',
        {
          active,
          scrolled: isScrolled,
          'has-global-insert': hasGlobalInsert,
          'has-local-insert': hasLocalInsert,
          'has-mobilenav-insert': hasMobileNavInsert,
          small: useSmallNav,
          'show-global': showGlobal,
          'hide-global': !showGlobal,
          'show-identity': showIdentity,
          'show-shortcuts': shortcuts,
          politics: showPolitics,
          [theme]: theme,
        },
        [styles.override],
      )}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: html,
      }}
    />
  );
}

Navbar.propTypes = {
  active: PropTypes.bool.isRequired,
  hasGlobalInsert: PropTypes.bool.isRequired,
  hasLocalInsert: PropTypes.bool.isRequired,
  hasMobileNavInsert: PropTypes.bool.isRequired,
  html: PropTypes.string.isRequired,
  shortcuts: PropTypes.bool.isRequired,
  showGlobal: PropTypes.bool.isRequired,
  showIdentity: PropTypes.bool.isRequired,
  showPolitics: PropTypes.bool,
  theme: PropTypes.string.isRequired,
  useSmallNav: PropTypes.bool,
  localAnimationDuration: PropTypes.number,
};

Navbar.defaultProps = {
  showPolitics: false,
  useSmallNav: false,
  localAnimationDuration: null,
};

const mapStateToProps = ({ navbar }) => ({
  active: navbar.active,
  hasGlobalInsert: navbar.hasGlobalInsert,
  hasLocalInsert: navbar.hasLocalInsert,
  shortcuts: navbar.shortcutsVisible,
  theme: navbar.theme,
  localAnimationDuration: navbar.localAnimationDuration,
});

export const HeaderNavbar = connect(mapStateToProps)(
  Navbar,
);
