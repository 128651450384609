import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Breakpoints from 'lib/Breakpoints';
import {
  providerFeaturedPropType,
  providerGenericPropType,
} from 'lib/CustomPropTypes/TVE';
import { ACCESS_ENABLER_OBJ } from 'lib/tve';

import {
  hideProviderIFrameAction,
  hideProviderSelectAction,
  loadProvidersFeatured as loadFeaturedAction,
  loadProvidersOptions as loadOptionsAction,
} from 'redux/modules/tve';

import Modal from 'components/Modal';
import SignInIFrame from 'components/TVE/SignInIFrame';

import BackButton from './BackButton';
import FeaturedGrid from './FeaturedGrid';
import Loader from './Loader';
import Map from './Map';
import Options from './Options';
import Results from './Results';
import Search from './Search';

import { block } from './helper';

import './styles.themed.scss';

// eslint-disable-next-line no-unused-vars
const mapStateToProps = ({ shared, tve }) => ({
  // vertical: shared.vertical,
  // Temporarily hardcoding vertical to msnbc so that the
  // provider select modal will show up on nbcnews.com
  vertical: 'msnbc',
  featured: tve.featured,
  options: tve.options,
  providerIFrameVisible: tve.providerIFrameVisible,
});

const mapActionsToProps = (dispatch) => ({
  hideProviderSelect: () => {
    dispatch(hideProviderSelectAction());
    dispatch(hideProviderIFrameAction());
  },
  hideProviderIFrame: () => dispatch(hideProviderIFrameAction()),
  loadFeatured: (vertical) => dispatch(loadFeaturedAction(vertical)),
  loadOptions: (vertical) => dispatch(loadOptionsAction(vertical)),
});

class ProviderSelect extends React.Component {
  searchTO = null;

  initialState = {
    searchResults: [],
    showFeatured: true,
    showFullList: false,
    showLoader: false,
  };

  static propTypes = {
    // MVPD displayName, ID and Logo list for featured buttons.
    featured: PropTypes.arrayOf(
      PropTypes.shape(providerFeaturedPropType),
    ),
    // MVPD displayName and ID list for select box.
    options: PropTypes.arrayOf(
      PropTypes.shape(providerGenericPropType),
    ),
    hideProviderIFrame: PropTypes.func,
    hideProviderSelect: PropTypes.func,
    loadOptions: PropTypes.func.isRequired,
    loadFeatured: PropTypes.func.isRequired,
    iframeRef: PropTypes.shape({}).isRequired,
    providerIFrameVisible: PropTypes.bool.isRequired,
    redirectUrl: PropTypes.string,
    vertical: PropTypes.string.isRequired,
  };

  static contextTypes = {
    store: PropTypes.shape({}),
  };

  static defaultProps = {
    featured: [],
    hideProviderIFrame: Function.prototype,
    hideProviderSelect: Function.prototype,
    options: [],
    redirectUrl: null,
  };

  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.state = {
      searchResults: [],
      showFeatured: true,
      showFullList: false,
      showLoader: false,
    };
  }

  componentDidMount() {
    const {
      featured, options, vertical, loadFeatured, loadOptions,
    } = this.props;

    if (!featured.length) {
      loadFeatured(vertical);
    }
    if (!options.length) {
      loadOptions(vertical);
    }

    Breakpoints.getMediumMQL().addListener(this.onBreakpointChange);
    Breakpoints.getLargeMQL().addListener(this.onBreakpointChange);
  }

  componentDidUpdate({ providerIFrameVisible: wasFrameVisible }) {
    const { providerIFrameVisible: isFrameVisible } = this.props;
    // IFrame became visible
    if (!wasFrameVisible && isFrameVisible) {
      // Setting component state from global state change in controlled manner
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ showLoader: false });
    }
  }

  componentWillUnmount() {
    Breakpoints.getMediumMQL().removeListener(this.onBreakpointChange);
    Breakpoints.getLargeMQL().removeListener(this.onBreakpointChange);
  }

  onBreakpointChange = () => {
    this.setState({
      fullListType: this.getFullListType(),
    });
  }

  getFullListType = () => {
    if (Breakpoints.isSorM()) {
      return 'select';
    }
    return 'list';
  }

  handleGoBack = (e) => {
    e.preventDefault();
    const { hideProviderIFrame } = this.props;
    hideProviderIFrame();
    this.setState({
      searchResults: [],
      showFeatured: true,
      showFullList: false,
      showLoader: false,
    });
  }

  handleShowFullList = (e) => {
    e.preventDefault();
    const { options } = this.props;
    this.setState({
      showFeatured: false,
      showFullList: true,
      showLoader: false,
      searchResults: options,
    });
  }

  handleSelectProvider = (e) => {
    e.preventDefault();
    const { target: { value } } = e;
    this.setState({
      fullListType: this.getFullListType(),
      searchResults: [],
      showFeatured: false,
      showFullList: false,
      showLoader: true,
    }, () => {
      this.selectProvider(value);
    });
  }

  selectProvider = (id) => {
    const { redirectUrl } = this.props;
    const AE = window[ACCESS_ENABLER_OBJ];
    AE.setSelectedProvider(null);
    AE.getAuthentication(redirectUrl);
    AE.setSelectedProvider(id);
  }

  handleSearch = (e, filter = false) => {
    e.preventDefault();

    if (this.searchTO) {
      clearTimeout(this.searchTO);
    }

    const { options } = this.props;

    this.searchTO = setTimeout(() => {
      const lower = this.inputRef.current.value.trim().toLowerCase();
      let searchResults = filter ? options : [];
      if (lower.length) {
        searchResults = options.filter((opt) => {
          const lowerDN = opt.displayName.toLowerCase();
          return lowerDN.indexOf(lower) > -1;
        });
      }
      this.setState({
        searchResults,
      });
    }, 250);
  }

  handleSearchClear = (e) => {
    e.preventDefault();
    const { showFullList } = this.state;
    const { options } = this.props;
    this.setState({
      searchResults: showFullList ? options : [],
    });
    this.inputRef.current.value = '';
  }

  renderOverlay() {
    const {
      showFeatured,
      showFullList,
      showLoader,
      searchResults,
      fullListType: fullListTypeStateValue,
    } = this.state;

    const {
      hideProviderSelect,
      iframeRef,
      featured,
      options,
      providerIFrameVisible,
    } = this.props;

    const fullListType = fullListTypeStateValue || this.getFullListType();

    return (
      <div className={block}>
        <button
          type="button"
          onClick={hideProviderSelect}
          className={`${block}__button ${block}__button__close`}
        >
          <span className="icon icon-close" />
        </button>
        <h1 className={`${block}__heading-h1`}>
          Sign In
        </h1>
        <p className={`${block}__description`}>
          Sign in with the same username and password you use to access your TV provider account.
        </p>
        <Map
          step2={showLoader || providerIFrameVisible}
        />

        {showFeatured && (
          <>
            <FeaturedGrid
              featured={featured}
              onClick={this.handleSelectProvider}
            />
            <p className={`${block}__search-text`}>
              <strong>Don’t see your provider?</strong>
              {' '}
              Search Below, or select from the
              {' '}
              <button
                type="button"
                onClick={this.handleShowFullList}
                className={`${block}__button ${block}__button__full-list`}
              >
                full list
              </button>
            </p>
            <Search
              inputRef={this.inputRef}
              onClickClear={this.handleSearchClear}
              onChange={this.handleSearch}
            />
            <Results
              onClick={this.selectProvider}
              results={searchResults}
            />
          </>
        )}

        {showFullList && (
          <>
            <BackButton
              text="Full List of TV Providers Below"
              onClick={this.handleGoBack}
            />
            {fullListType === 'select' && (
              <>
                <Options
                  options={options}
                  onChange={this.handleSelectProvider}
                />
              </>
            )}
            {fullListType === 'list' && (
              <>
                <Search
                  filter
                  inputRef={this.inputRef}
                  onClickClear={this.handleSearchClear}
                  onChange={this.handleSearch}
                  results={searchResults}
                />
                <Results
                  fill
                  onClick={this.selectProvider}
                  results={searchResults}
                />
              </>
            )}
          </>
        )}

        {providerIFrameVisible && (
          <>
            <BackButton
              text="Verify Account"
              onClick={this.handleGoBack}
            />
            <SignInIFrame ref={iframeRef} />
          </>
        )}

        {showLoader && (
          <Loader />
        )}
      </div>
    );
  }

  render() {
    return (
      <Modal preventBackgroundScroll>
        {this.renderOverlay()}
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapActionsToProps)(ProviderSelect);
