import React from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { withCookies, Cookies } from 'react-cookie';
import PropTypes from 'prop-types';
import Script from 'next/script';
import get from 'lodash.get';
import { parse } from 'node-html-parser';

import { setTriggerTop as setTriggerTopAction } from 'redux/modules/layout';
import { bulkUpdate } from 'redux/modules/navbar';

import Breakpoints from 'lib/Breakpoints';
import { FeatureFlagContext } from 'lib/ContextTypes';
import HeadlinePropType from 'lib/CustomPropTypes/headline';
import { hasSingleNav } from 'lib/hasSingleNav';
import loadScript from 'lib/loadScript';
import { stripQueryParams } from 'lib/urlUtils';
import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';
import {
  MYNEWS_ENABLED, ENABLE_ELECTIONS_NAV_THEME, SHOW_MOBILE_WEB_NAV, LOCAL_NAV_INSERT_OVERRIDE, TRENDING_NAV,
} from 'lib/brandFeatures';
import MiniPlayerTease from 'components/MiniPlayerTease';
import { AdSponsor } from 'components/Ad/Sponsor';
import { Save } from 'components/SocialShareMenu/Save';

import {
  USA,
  GEOLOCATION_COOKIE,
} from 'lib/myNewsConstants';

import { HeaderFallback } from './Fallback';
import { HeaderNavbar } from './Navbar';
import { ElectionsNav } from './ElectionsNav';
import { SkipToContentOverlay } from './SkipToContentOverlay';
import { TrendingNav } from './TrendingNav';

import './styles.themed.scss';

const mapStateToProps = ({
  navbar,
  article,
  recipe,
  shared,
}) => {
  let taxonomy;

  const computedContentId = get(article, 'content[0].id', '');
  if (recipe?.current) {
    taxonomy = get(recipe, ['current', 'taxonomy', 'primaryTopic', 'slug'], null);
  } else {
    taxonomy = get(article, ['content', 0, 'taxonomy', 'primaryTopic', 'slug'], null);
  }

  return ({
    headline: navbar.headline,
    shareUrl: navbar.shareUrl,
    shortcuts: navbar.shortcutsVisible,
    taxonomy,
    contentId: computedContentId,
    vertical: get(shared, 'vertical', 'news'),
  });
};

const mapActionsToProps = {
  setTriggerTop: setTriggerTopAction,
  updateNavConfig: bulkUpdate,
};

class ServiceHeader extends React.Component {
  static contextType = FeatureFlagContext;

  static propTypes = {
    adsEnabled: PropTypes.bool,
    currentPath: PropTypes.string,
    header: PropTypes.shape({
      css: PropTypes.shape({}),
      js: PropTypes.shape({
        src: PropTypes.arrayOf(
          PropTypes.string,
        ),
      }),
      html: PropTypes.arrayOf(
        PropTypes.string,
      ),
    }).isRequired,
    headline: HeadlinePropType,
    pageView: PropTypes.string,
    setTriggerTop: PropTypes.func.isRequired,
    shareUrl: PropTypes.string,
    shortcuts: PropTypes.bool.isRequired,
    taxonomy: PropTypes.string,
    contentId: PropTypes.string,
    updateNavConfig: PropTypes.func,
    vertical: PropTypes.string.isRequired,
    isNavbarSticky: PropTypes.bool,
    cookies: PropTypes.instanceOf(Cookies).isRequired,
    isElectionsNavEnabled: PropTypes.bool,
  };

  static defaultProps = {
    adsEnabled: true,
    currentPath: '/',
    headline: null,
    pageView: null,
    shareUrl: null,
    taxonomy: null,
    contentId: null,
    updateNavConfig: Function.prototype,
    isNavbarSticky: true,
    isElectionsNavEnabled: false,
  };

  parsedNavbar = null;

  constructor(props) {
    super(props);

    this.state = {
      ready: false,
      isShowBreakingNewsDigest: false,
      isUSA: false,
      isMobile: false,
    };
  }

  componentDidMount() {
    if (this.hasHeader() && window) {
      const {
        header,
        headline,
        shareUrl,
        shortcuts,
        vertical,
        pageView,
        cookies: { cookies },
      } = this.props;

      const queryParameters = new URLSearchParams(window.location.search);
      const experimentQueryParam = queryParameters.get('ex');

      const {
        featureFlagQueryParam,
      } = this.context;

      let showBreakingNewsDigest = false;
      if (vertical === 'news') {
        showBreakingNewsDigest = experimentQueryParam === 'digest';
      }
      this.setState({
        isMobile: Breakpoints.isS(),
        isUSA: cookies[GEOLOCATION_COOKIE] === USA,
        isShowBreakingNewsDigest: showBreakingNewsDigest,
      });


      const {
        js: {
          src = [],
        },
      } = header;

      // Set brand/vertical in config object
      if (typeof window.HFSconfig === 'undefined') {
        window.HFSapi = {};
        window.HFSconfig = {
          brand: vertical,
          header: {
            primary: get(headline, 'primary'),
            social: get(headline, 'social'),
            url: this.getSocialShareUrl(shareUrl),
            shortcuts,
            showBreakingNewsDigest,
            enableIdentity: !!getFeatureConfigForBrand(MYNEWS_ENABLED, vertical),
          },
          pageView,
        };

        if (!this.hasGlobalNav()) {
          window.HFSconfig.header.globalNav = false;
        }

        if (hasSingleNav(vertical, featureFlagQueryParam)) {
          window.HFSconfig.header.useSmallNav = true;
        }
      }

      let scriptsLoaded = 0;
      // Attach scripts
      src.forEach((script) => {
        loadScript(script)
          .then(() => {
            scriptsLoaded += 1;
            // Fire window event to initialize header
            if (scriptsLoaded >= src.length) {
              const event = new CustomEvent('HFS.ready', { detail: 'header' });
              window.dispatchEvent(event);
              // Set ready
              this.setState({ ready: true });
            }
          });
      });

      // Observe height changes
      window.addEventListener('HFS.recalculateHeight', this.recalculateHeight);
      Breakpoints.getSmallMQL()?.addListener(this.onBreakpointChange);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('HFS.recalculateHeight', this.recalculateHeight);
    Breakpoints.getSmallMQL()?.removeListener(this.onBreakpointChange);
  }

  onBreakpointChange = () => {
    this.setState({ isMobile: Breakpoints.isS() });
  }

  getSocialShareUrl = (path) => {
    const { vertical } = this.props;
    if (/^\//.test(path)) {
      switch (vertical) {
        case 'today':
          return `https://www.today.com${path}`;
        case 'msnbc':
          return `https://www.msnbc.com${path}`;
        case 'noticias':
        case 'deportes':
          return `https://www.telemundo.com${path}`;
        case 'entretenimiento':
          return `https://www.telemundo.com${path}`;
        case 'shows':
          return `https://www.telemundo.com${path}`;
        case 'telemundo':
          return `https://www.telemundo.com${path}`;
        default:
          return `https://www.nbcnews.com${path}`;
      }
    }
    return path;
  }

  recalculateHeight = () => {
    if (this.wrapperRef?.getBoundingClientRect) {
      const { setTriggerTop } = this.props;
      const { top } = this.wrapperRef.getBoundingClientRect();
      const smallAd = Breakpoints.isS() && window.pageYOffset <= 70;
      const medAd = Breakpoints.isM() && window.pageYOffset <= 122;
      const largeAd = Breakpoints.isL() && window.pageYOffset <= 282;
      if (smallAd || medAd || largeAd) {
        setTriggerTop(top + window.pageYOffset); // change triggerTop according to min height of ads on diff screen size
      }
    }
  }

  hasHeader = () => {
    const { header } = this.props;
    return header?.html?.length;
  }

  onGlobalInsertShow = () => {
    if (window?.HFSapi && typeof window.HFSapi.enableGlobalInsert === 'function') {
      // Update stored navbar configuration
      const { updateNavConfig } = this.props;
      updateNavConfig({ hasGlobalInsert: true });
      // Enable global insert via HFS API
      window.HFSapi.enableGlobalInsert();
    }
  }

  onLocalInsertShow = () => {
    // eslint-disable-next-line max-len
    if (typeof window === 'object' && window.HFSapi && typeof window.HFSapi.enableLocalInsert === 'function') {
      // Update stored navbar configuration
      const { updateNavConfig } = this.props;
      updateNavConfig({ hasLocalInsert: true, useSmallNav: true });

      // Enable global insert via HFS API
      window.HFSapi.enableLocalInsert();
    }
  }

  insertElectionsNav = () => {
    const { ready } = this.state;
    const { isElectionsNavEnabled, taxonomy, vertical } = this.props;
    if (isElectionsNavEnabled) {
      if (ready && this.wrapperRef?.querySelector) {
        const container = this.wrapperRef.querySelector('.hfsh');
        const electionsNavContainer = document.createElement('div');
        electionsNavContainer.setAttribute('class', 'elections-nav-wrapper');
        container.appendChild(electionsNavContainer);

        const electionsNavTheme = getFeatureConfigForBrand(ENABLE_ELECTIONS_NAV_THEME, vertical);

        if (!electionsNavTheme) return null;

        container.appendChild(electionsNavContainer);
        const { yearPosition } = electionsNavTheme;
        const year = taxonomy?.split('-')[yearPosition];

        return ReactDOM.createPortal(
          <ElectionsNav year={year} />,
          electionsNavContainer,
        );
      }
    }
    return null;
  }

  insertSaveArticle = () => {
    const { ready } = this.state;
    const { pageView, vertical, contentId } = this.props;
    const isArticle = pageView === 'article';
    const savingArticleEnabled = getFeatureConfigForBrand(MYNEWS_ENABLED, vertical);
    const isToday = vertical === 'today';

    if (!isToday && isArticle && savingArticleEnabled) {
      if (ready && this.wrapperRef?.querySelector) {
        const container = this.wrapperRef.querySelector('.share-ul');
        const saveNavContainer = document.createElement('div');
        saveNavContainer.setAttribute('tabindex', '-1');
        container.appendChild(saveNavContainer);

        return ReactDOM.createPortal(<Save contentId={contentId} contentType="article" navbarPlacement />, saveNavContainer);
      }
    }
    return null;
  };

  /**
   * Test unique case(s) where mini tease appears in non-standard position
   */
  hasTeaseBelowNav = () => {
    const {
      currentPath,
      pageView,
      vertical,
    } = this.props;
    return (
      vertical === 'msnbc'
      && pageView === 'front'
      && stripQueryParams(currentPath) === '/'
      && Breakpoints.isS()
    );
  }

  renderLocalInsert = (showMiniTease) => {
    const { vertical } = this.props;
    const { ready } = this.state;
    let selector = '.js-global-nav-insert';
    let callback = this.onGlobalInsertShow;
    const { featureFlagQueryParam, 'trending-nav': hasTrending } = this.context;
    const trendingNavEnabled = hasTrending && getFeatureConfigForBrand(TRENDING_NAV, vertical);


    if (ready && this.wrapperRef?.querySelector) {
      if (this.hasTeaseBelowNav()) {
        selector = '.js-portal-below-header';
        callback = () => { };
      }

      const localNavInsertOverride = getFeatureConfigForBrand(LOCAL_NAV_INSERT_OVERRIDE, vertical);

      if (hasSingleNav(vertical, featureFlagQueryParam) || localNavInsertOverride) {
        selector = '.js-local-nav-insert';
        callback = this.onLocalInsertShow;
      }

      const container = this.wrapperRef.querySelector(selector);

      if (container) {
        if (trendingNavEnabled) {
          return ReactDOM.createPortal(
            <TrendingNav />,
            container,
          );
        }
        if (showMiniTease) {
          return ReactDOM.createPortal(
            <MiniPlayerTease
              useLocalTease={hasSingleNav(vertical, featureFlagQueryParam)}
              onShowTease={callback}
            />,
            container,
          );
        }
      }
    }
    return null;
  }

  insertSponsorAd = () => {
    const { ready } = this.state;
    const {
      adsEnabled,
      vertical,
      pageView,
    } = this.props;

    if (pageView === 'myNews') {
      return null;
    }

    if (
      ready
      && this.wrapperRef?.querySelector
      && adsEnabled
      && vertical !== 'sponsoredcontent'
    ) {
      const container = this.wrapperRef.querySelector('.js-sponsor-wrap');
      if (container) {
        return ReactDOM.createPortal(<AdSponsor />, container);
      }
    }
    return null;
  }

  /**
   * Convert header navbar markup into traversable object.
   * TODO (BENTO-13916): separate inner navbar markup and configuration at HFS level
   */
  getParsedMarkup = () => {
    if (this.parsedNavbar === null) {
      const {
        header: {
          html: [html],
        } = {},
      } = this.props;
      const parsed = parse(html);
      const navElement = parsed.querySelector('nav');
      this.parsedNavbar = navElement || false;
    }
    return this.parsedNavbar;
  }

  /**
   * Extract and process inner html from HFS navbar markup.
   * @param {string} html
   */
  getProcessedMarkup = (html) => {
    const { innerHTML } = this.getParsedMarkup() || {};
    return innerHTML || html;
  }

  /**
   * Check classes on navbar element for given class.
   * @param {string} className
   */
  hasConfiguredNavClass = (className) => {
    const {
      classNames: navClassNames = [],
    } = this.getParsedMarkup() || {};
    return navClassNames.includes(className);
  }

  hasGlobalNav = () => {
    const { isElectionsNavEnabled, vertical } = this.props;
    const { featureFlagQueryParam } = this.context;
    if (isElectionsNavEnabled && getFeatureConfigForBrand(ENABLE_ELECTIONS_NAV_THEME, vertical)) {
      return false;
    }
    if (hasSingleNav(vertical, featureFlagQueryParam)) {
      return false;
    }
    if (!this.hasConfiguredNavClass('show-global')) {
      return false;
    }
    return true;
  }

  render() {
    if (!this.hasHeader()) {
      return <HeaderFallback />;
    }

    const {
      header: {
        js: { html: scripts },
        html: [html],
      } = {},
      vertical,
      isNavbarSticky,
      currentPath,
      isElectionsNavEnabled,
    } = this.props;

    const {
      isUSA, isShowBreakingNewsDigest, isMobile,
    } = this.state;
    const { featureFlagQueryParam } = this.context;
    const showMiniTease = isShowBreakingNewsDigest ? !isMobile : true;
    const identityEnabled = !!getFeatureConfigForBrand(MYNEWS_ENABLED, vertical);
    const showIdentity = isUSA && identityEnabled;
    const hasMobileNavInsert = vertical === 'news' ? getFeatureConfigForBrand(SHOW_MOBILE_WEB_NAV, vertical) && currentPath === '/' : getFeatureConfigForBrand(SHOW_MOBILE_WEB_NAV, vertical);
    const isElectionNavEnabledForBrand = isElectionsNavEnabled
      && getFeatureConfigForBrand(ENABLE_ELECTIONS_NAV_THEME, vertical);

    let showPolitics = false;

    if (isElectionNavEnabledForBrand) {
      showPolitics = true;
    }

    return (
      <>
        {scripts && Array.isArray(scripts) && scripts.map((src) => (
          <Script
            key={src.replace(/\s+/g, '').substr(0, 10)}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: decodeURIComponent(src) }}
          />
        ))}

        <div
          className={classNames(
            'layout-header',
            { 'show-elections-nav': isElectionNavEnabledForBrand },
            { notSticky: !isNavbarSticky },
          )}
          id="hfs-header"
          data-activity-map="hfs-header"
          ref={(el) => { this.wrapperRef = el; }}
        >
          <SkipToContentOverlay />
          <HeaderNavbar
            html={this.getProcessedMarkup(html)}
            showGlobal={this.hasGlobalNav()}
            hasMobileNavInsert={hasMobileNavInsert}
            showIdentity={showIdentity}
            showPolitics={showPolitics}
            useSmallNav={hasSingleNav(vertical, featureFlagQueryParam)}
          />
          {isNavbarSticky && (
            <div className="hfsh-spacer" />
          )}

          {/* Render tease portal */}
          {this.renderLocalInsert(showMiniTease)}

          {/* Render sponsorAd portal */}
          {this.insertSponsorAd()}

          {this.insertElectionsNav()}
          {isUSA
            && this.insertSaveArticle()}
        </div>

        <div className="js-portal-below-header" />
        <div id="header-end" tabIndex={-1} />
      </>
    );
  }
}

export default connect(mapStateToProps, mapActionsToProps)(
  withCookies(ServiceHeader),
);
