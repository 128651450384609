import React from 'react';
import PropTypes from 'prop-types'; // ES6

import loadScript from 'lib/loadScript';

import './styles.themed.scss';

const elementId = 'embed-program-guide';

class ProgramGuide extends React.Component {
  static propTypes = {
    url: PropTypes.string.isRequired,
  };

  componentDidMount() {
    loadScript('https://nodeassets.nbcnews.com/cdnassets/pym/1.3.2/pym.min.js')
      .then(() => {
        let { url } = this.props;

        if (window.pym && url) {
          // Strip Pym query string params from url that might have been copied.
          url = url.replace(/&(childId|parentUrl)=[^&]+/g, '');
          // Pym adds iframe and establishes communication for controlling height.
          //* Each iframe needs to be created with a new pym Parent, otherwise subsequent iframes
          //* will overwrite the prior ones
          this.pymParent = new window.pym.Parent(elementId, url);

          this.pymParent.onMessage('navigateMessage', this.onNavigateMessage);
        }
      });
  }

  render() {
    return (
      <div data-testid="programGuideEmbed" className="program-guide__embed">
        <div id={elementId} />
      </div>
    );
  }
}

export default ProgramGuide;
