/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Link from 'components/Link';

const block = 'mini-tease-local';

function WatchNowTease({
  isFlagged,
  url,
  icid,
}) {
  return (
    <div
      className={classNames(
        'watch-now',
        block,
        { [`${block}--live`]: isFlagged },
      )}
    >
      <Link className={`${block}__link`} href={url} icid={icid} aria-label="Watch Live button">
        <svg className={`${block}__logo`} width="81" height="13" viewBox="0 0 81 11" xmlns="http://www.w3.org/2000/svg" fill="currentColor" aria-labelledby="watch_live_logo">
          <title id="watch_live_logo">Watch live</title>
          <path
            d="M5.15433 0.484998H7.42078L8.68195 7.90578H8.75506L10.0893 0.484998H12.3923L10.0711 12H7.62183L6.251 4.08572H6.17789L4.80706 12H2.35783L0.0365556 0.484998H2.48578L3.7835 7.90578H3.85661L5.15433 0.484998ZM15.1968 0.484998H18.1578L21.4661 12H18.9803L18.2675 9.34972H14.9044L14.1733 12H11.8886L15.1968 0.484998ZM16.5677 3.29978L15.4344 7.41228H17.7374L16.6408 3.29978H16.5677ZM26.1224 12H23.6732V2.51383H20.7487V0.484998H29.1017V2.51383H26.1224V12ZM29.319 6.62633V5.85867C29.319 2.44072 30.7447 0.283943 33.6508 0.283943C36.5022 0.283943 37.745 2.27622 37.745 5.14583V5.43828H35.4238V5.16411C35.4238 3.29978 34.8937 2.33105 33.6874 2.33105C32.4445 2.33105 31.8413 3.31805 31.8413 5.36517V7.11983C31.8413 9.16694 32.4445 10.1539 33.6874 10.1539C34.8937 10.1539 35.4238 9.18522 35.4238 7.32089V7.04672H37.745V7.33917C37.745 10.2088 36.5022 12.2011 33.6508 12.2011C30.7447 12.2011 29.319 10.0443 29.319 6.62633ZM41.415 12H38.9475V0.484998H41.415V5.14583H45.0889V0.484998H47.5564V12H45.0889V7.13811H41.415V12ZM52.1739 0.484998H54.6414V9.97117H59.0281V12H52.1739V0.484998ZM60.1169 0.484998H62.5844V12H60.1169V0.484998ZM69.1964 12H66.7106L63.3841 0.484998H65.9064L68.0083 8.25306H68.0814L70.2017 0.484998H72.5229L69.1964 12ZM80.2345 9.97117V12H73.3255V0.484998H80.1979V2.51383H75.7381V5.14583H79.1378V7.13811H75.7381V9.97117H80.2345Z"
            fill="currentColor"
          />
        </svg>
      </Link>
    </div>
  );
}

WatchNowTease.propTypes = {
  isFlagged: PropTypes.bool,
  url: PropTypes.string,
  icid: PropTypes.string,
};

WatchNowTease.defaultProps = {
  isFlagged: true,
  url: 'https://www.nbcnews.com/now',
  icid: 'now_hp_header',
};

export { WatchNowTease };
