/* eslint-disable no-underscore-dangle,no-undef */
if (__CLIENT__) {
  window.__nbcnd__ = window.__nbcnd__ || {};
  window.__nbcnd__.adQueue = window.__nbcnd__.adQueue || [];
}

export function adReady(fn) {
  if (__CLIENT__) {
    window.__nbcnd__.adQueue.push(fn);
  }
}
/* eslint-enable no-underscore-dangle,no-undef */
