import React from 'react';
import { configTVE } from 'lib/tve';

const SignInIFrame = React.forwardRef((_, ref) => (
  <div
    id="mvpddiv"
    ref={ref}
  >
    <iframe
      loading="lazy"
      title="provider signin"
      id="mvpdframe"
      name="mvpdframe"
      src={configTVE.iframeSrc}
      frameBorder="0"
    />
  </div>
));

export default SignInIFrame;
