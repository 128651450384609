import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import images from './images';

export const ERROR_TYPES = {
  ADS: 'ads',
  BROWSER: 'browser',
  ERROR: 'error',
  STREAM: 'stream',
};

const mapStateToProps = ({ shared, video }) => ({
  error: video.error,
  vertical: shared.vertical,
});

export function TVEVideoSlate({ error, vertical }) {
  if (!error || !images[vertical]) {
    return null;
  }

  const {
    adsAreDown,
    generic,
    streamIsDown,
    unsupportedBrowser,
  } = images[vertical];

  let errorAlt = 'An error has occurred, please try again later';
  let errorSrc = generic;

  switch (error) {
    case ERROR_TYPES.ADS:
      errorAlt = 'We\'ll be right back after the break';
      errorSrc = adsAreDown;
      break;

    case ERROR_TYPES.BROWSER:
      errorAlt = 'Your web browser is not supported';
      errorSrc = unsupportedBrowser;
      break;

    case ERROR_TYPES.STREAM:
      errorAlt = 'Not available, please try again later';
      errorSrc = streamIsDown;
      break;

    default:
  }

  return <ImageSlate src={errorSrc} alt={errorAlt} />;
}

/**
 * @param {object} props
 * @param {string} props.src
 * @param {string} props.alt
 */
export function ImageSlate({ src, alt }) {
  return (
    <img
      alt={alt}
      src={src}
      style={{
        width: '100%',
        height: '100%',
      }}
    />
  );
}
ImageSlate.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

TVEVideoSlate.propTypes = {
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  vertical: PropTypes.string.isRequired,
};

TVEVideoSlate.defaultProps = {
  error: false,
};

export default connect(mapStateToProps)(TVEVideoSlate);
