export const block = 'tve__provider-select';

export const gridColCountS = 3;
export const gridColCountM = 5;

export default {
  block,
  gridColCountS,
  gridColCountM,
};
