const TOC_TAXONOMY_PATH = '/workflow-toc';

/**
 * Evaluates if the Table of Contents should be shown.
 *
 * @param {boolean} tableOfContentsFlag
 * @param {string} vertical
 * @param {Array} articleTaxonomies
 * @returns {boolean}
 */
export function shouldShowTableOfContents(
  tableOfContentsFlag,
  vertical,
  articleTaxonomies,
) {
  return (
    tableOfContentsFlag
    && ['today', 'select'].includes(vertical)
    && articleTaxonomies.some((t) => t.path.includes(TOC_TAXONOMY_PATH))
  );
}

/**
 * Retrieves the Table of Contents title from the article taxonomy.
 *
 * @param {Array} articleTaxonomies
 * @returns {string}
 */
export function getTableOfContentsLabel(articleTaxonomies) {
  const tocTaxonomy = articleTaxonomies?.find((t) => t.path.includes(TOC_TAXONOMY_PATH));
  return tocTaxonomy ? tocTaxonomy.name.trim() : 'In the article';
}

/**
 * Retrieves a list of anchors within the body of the article.
 *
 * @param {Array} articleBody
 * @param {string} vertical
 * @returns {Array}
 */
export function getTableOfContentsEntries(articleBody, vertical) {
  return articleBody.reduce((entries, item) => {
    if (item.type === 'markup' && item.element === 'h2' && item.elementId) {
      entries.push({
        linkText: item.html,
        href: `#anchor-${item.elementId}`,
      });
      return entries;
    }

    if (item.type === 'embeddedWidget' && item.widget.name === 'CUSTOM_EMBED' && item.elementId) {
      const { embed } = item.widget.properties;
      const embedType = embed.type;

      if (embedType === 'FAQ') {
        entries.push({
          linkText: embed.headline,
          href: `#anchor-${item.elementId}`,
        });
        return entries;
      }

      if (embedType === 'COMMERCE_QUICK_TAKES') {
        entries.push({
          // by editor convention, they want the title to display for Today and subtitle for Select because
          // the title is always "Selected." for Select
          linkText: vertical === 'today' ? embed.title : embed.subtitle,
          href: `#anchor-${item.elementId}`,
        });
        return entries;
      }

      if (embedType === 'ALL_PURPOSE_EMBED' && vertical === 'today') {
        if (embed.code.includes('opinary-widget-embed')) {
          entries.push({
            linkText: embed.cmsDisplayName || 'Poll',
            href: `#anchor-${item.elementId}`,
          });
        }
        return entries;
      }
    }

    return entries;
  }, []);
}
