import { verticalSlugMap } from 'lib/vertical';

/**
 * Boolean function to check to see if we are on an MSNBC daily opinion page. This triggers
 * additional styles to show indicate that this page is opinion, not news.
 *
 * @param {VerticalType} vertical
 * @param {TaxonomyCollection} taxonomy
 *
 * @returns {Boolean}
 */
export const isMSNBCDaily = (vertical, taxonomy) => {
  const {
    msnbc: MSNBC,
  } = verticalSlugMap;

  const primarySectionName = taxonomy?.primarySection?.name ?? '';
  const primaryTopicName = taxonomy?.primaryTopic?.name ?? '';

  return vertical === MSNBC && primarySectionName === 'Opinion' && primaryTopicName === 'Opinion';
};
