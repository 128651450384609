import Breakpoints from 'lib/Breakpoints';

/**
 * Boolean function to check if Taboola should show
 * @param {object} article
 * @param {boolean} showTaboola - showTaboola state value for article page
 * @returns {Boolean}
 */
export const shouldShowTaboola = (article, showTaboola) => {
  if (!article) return false;

  const nativeAd = article?.nativeAd ?? null;
  // BENTO-9179: Taboola should display when ads don't, except on paid content
  return (!nativeAd) && (!!showTaboola || Breakpoints.isS());
};
