import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';

import { isTelemundoVertical } from 'lib/vertical';
import styles from './styles.module.scss';

const mapStoreToProps = ({
  shared,
}) => ({
  vertical: shared.vertical,
});

const FooterDefault = ({ vertical }) => {
  const { t } = useTranslation();

  let iconLogoClass = 'icon-peacock';
  if (vertical === 'today') {
    iconLogoClass = 'icon-today-sunrise';
  }
  if (isTelemundoVertical(vertical)) {
    iconLogoClass = 'icon-telemundo-t';
  }

  return (
    <nav
      className={classNames(
        styles.footerDefault,
        'founders-mono f2 lh-none ls-tight dn-print ttu',
      )}
      data-test="test--footer"
    >
      <a href="/" title={t('return to Home page')}>
        <span className={`icon ${iconLogoClass} ${styles.backdropLogo}`} />
      </a>

      <div className={styles.row}>
        <p className={styles.copyright}>
          &copy;
          {' '}
          {new Date().getFullYear()}
          {' '}
          NBC UNIVERSAL
        </p>
      </div>
    </nav>
  );
};

FooterDefault.propTypes = {
  vertical: PropTypes.string.isRequired,
};

export default connect(mapStoreToProps)(FooterDefault);
