import PropTypes from 'prop-types';

export default {
  guid: PropTypes.string,
  startTime: PropTypes.number,
  startTimeFormatted: PropTypes.string,
  program: PropTypes.shape({
    description: PropTypes.string,
    title: PropTypes.string,
  }),
};
