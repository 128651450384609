import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import getConfig from 'next/config';

import { showProviderSelectAction } from 'redux/modules/tve';

import WithTVEAuthProvider from 'components/TVE/AuthProvider';
import ProviderSelect from 'components/TVE/ProviderSelect';

const {
  publicRuntimeConfig: {
    DISABLE_AUTH_TVE_MSNBC,
  },
} = getConfig();

const mapStateToProps = ({ tve }) => ({
  authenticated: tve.authenticated,
  providerSelectVisible: tve.providerSelectVisible,
});

const mapActionsToProps = (dispatch) => ({
  showProviderSelect: () => dispatch(showProviderSelectAction()),
});

class StandaloneSignIn extends React.Component {
  static propTypes = {
    authenticated: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string,
    ]),
    children: PropTypes.node,
    className: PropTypes.string,
    providerSelectVisible: PropTypes.bool,
    showProviderSelect: PropTypes.func,
    redirectUrl: PropTypes.string.isRequired,
  };

  static defaultProps = {
    authenticated: null,
    children: null,
    className: null,
    providerSelectVisible: false,
    showProviderSelect: Function.prototype,
  };

  constructor(props) {
    super(props);
    this.iframeRef = React.createRef();
  }

  handleSignIn = (e) => {
    e.preventDefault();
    // Open MVPD overlay
    const { showProviderSelect } = this.props;
    showProviderSelect();
  }

  render() {
    // Disable sign in
    const { authenticated } = this.props;
    if (authenticated === true || DISABLE_AUTH_TVE_MSNBC) {
      return null;
    }

    const {
      children, className, providerSelectVisible, redirectUrl,
    } = this.props;

    return (
      <>
        <WithTVEAuthProvider
          iframeRef={this.iframeRef}
          disableBackgroundLogin
        />

        {providerSelectVisible && (
          <ProviderSelect
            iframeRef={this.iframeRef}
            redirectUrl={redirectUrl}
          />
        )}

        <button
          className={className}
          onClick={this.handleSignIn}
          type="button"
        >
          {children}
        </button>
      </>
    );
  }
}

export default connect(mapStateToProps, mapActionsToProps)(
  StandaloneSignIn,
);
