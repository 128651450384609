import React from 'react';

const IconClose = () => (
  <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.67853 1.67791L18.6961 18.6955" />
    <path d="M18.6951 1.67804L1.67752 18.6957" />
  </svg>
);

export { IconClose };
