import React from 'react';
import i18next from 'i18next';

class LoadingText extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingText: i18next.t('Loading'),
    };
  }

  componentDidMount() {
    this.loadingInterval = setInterval(this.updateText, 300);
  }

  componentWillUnmount() {
    clearInterval(this.loadingInterval);
  }

  updateText = () => this.setState(({ loadingText }) => {
    let newText = loadingText;
    if (loadingText.length === 10) newText = i18next.t('Loading');
    else newText += '.';

    return { loadingText: newText };
  });

  render() {
    const { loadingText } = this.state;
    return (
      <span className="fs-italic f3 f5-l">
        {loadingText}
      </span>
    );
  }
}

export default LoadingText;
